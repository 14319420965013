import { ResourceLanguage } from 'i18next'
import {
	AccountRoutes,
	AddressErrors,
	AddressTranslations,
	Cart,
	Delivery,
	DeliveryStatusTranslations,
	ErrorTranslations,
	FilterColor,
	FooterPage,
	MenuCategoryTranslations,
	OrderStatusTranslation,
	Password,
	PasswordRecover,
	Payment,
	Product,
	ReturnOrder,
	SignIn,
	SignUp,
	Translations,
} from './translations.enum'

const translations: Record<
	| Translations
	| MenuCategoryTranslations
	| AccountRoutes
	| FilterColor
	| AddressTranslations
	| AddressErrors
	| Password
	| Cart
	| Payment
	| SignUp
	| SignIn
	| Product
	| FooterPage
	| Delivery
	| ReturnOrder
	| DeliveryStatusTranslations
	| PasswordRecover
	| OrderStatusTranslation
	| ErrorTranslations,
	string
> = {
	SEARCH: 'Buscar',
	COOKIE_POLICY: 'Política de Cookies',
	TERMS_CONDITIONS: 'Términos y Condiciones',
	PRIVACY_POLICY: 'Política de Privacidad',
	FAQS: 'FAQs',
	SPANISH: 'Español',
	ENGLISH: 'Inglés',
	ITALIAN: 'Italiano',
	PORTUGUESE: 'Portugués',
	SHOPPING_BAG: 'Carrito',
	WISHLIST: 'Lista de deseos',
	MYFINAEJERIQUE: 'Mi cuenta',
	CUSTOMER_SERVICE: 'Atención al Cliente',
	NEED_HELP_ORDER: '¿Necesitas ayuda con tu pedido?',
	COLOR: 'Color',
	SIZE: 'Talla',
	SIZE_CHART: 'Guia de tallas',
	REGION: 'Región',
	LANGUAGE: 'Idioma',
	SHIPPING_TO: 'Envío a',
	SHIPPING_LOCATION: 'España',
	BABY: 'Bebé',
	NEW_BORN: 'Recién nacido',
	CHILDREN: 'Infantil',
	SIGN_IN: 'Iniciar sesión',
	SIGN_OUT: 'Cerrar sesión',
	SIGN_UP: 'Registrate',
	LOADING: 'Cargando',
	DESCRIPTION: 'Descripción',
	DETAILS: 'Detalles',
	DELIVERY: 'Envío',
	CONTACT_US: 'Contáctanos',
	SIMILAR_PRODUCTS: 'Productos similares',
	YOU_MAY_ALSO_LIKE: 'También te puede gustar',
	ADD_TO_CART: 'Añadir al carrito',
	CLEAR_FILTERS: 'Limpiar filtros',
	FILTER: 'Filtrar',
	APPLY: 'Aplicar',
	ACCOUNT_OVERVIEW: 'Resumen de cuenta',
	ORDER_HISTORY: 'Historial de pedidos',
	ADDRESS_BOOK: 'Libreta de direcciones',
	ADDRESS_EDIT: 'Editar dirección',
	ADDRESS_NEW: 'Nueva dirección',
	CHANGE_PASSWORD: 'Cambiar contraseña',
	CONTINUE_TO_PAYMENT: 'Continuar al pago',
	ORDER_VIEW: 'Ver pedido',
	RED_PALETTE: 'Rojos',
	PINK_PALETTE: 'Rosas',
	PURPLE_PALETTE: 'Morados',
	BLUE_PALETTE: 'Azules',
	GREEN_PALETTE: 'Verdes',
	YELLOW_PALETTE: 'Amarillos',
	ORANGE_PALETTE: 'Naranjas',
	BROWN_PALETTE: 'Marrones',
	GRAY_PALETTE: 'Grises',
	WHITE_PALETTE: 'Blancos',
	BLACK_PALETTE: 'Negros',
	MY_INFO: 'Información de mi cuenta',
	ORDER_RETURN: 'Devolución de pedido',
	RETURNS: 'Devoluciones',
	RETURN_VIEW: 'Ver devolución',
	ORDER_HISTORY_DESCRIPTION: 'Comprueba tu historial de pedidos',
	ADDRESS_BOOK_DESCRIPTION: 'Gestiona tu libreta de direcciones',
	PASSWORD_CHANGE_DESCRIPTION: 'Cambia tu contraseña',
	RETURN_DESCRIPTION: 'Comprueba el estado de tus devoluciones',
	WISHLIST_DESCRIPTION: 'Lista de deseos de productos de Fina Ejerique',
	ACCOUNT_OVERVIEW_DESCRIPTION: 'Hola',
	ADDRESS: 'Dirección',
	ADDRESS_NO_ADDRESSES: 'Crea tu primera dirección',
	ADDRESS_FIRST_NAME: 'Nombre',
	ADDRESS_LAST_NAME: 'Apellidos',
	ADDRESS_COUNTRY: 'País',
	ADDRESS_CITY: 'Ciudad',
	ADDRESS_PROVINCE: 'Provincia',
	ADDRESS_POSTAL_CODE: 'Código postal',
	ADDRESS_STREET_AND_NUMBER: 'Calle y número',
	ADDRESS_ADDITIONAL_DATA: 'Piso/Puerta u otro (Opcional)',
	ADDRESS_PHONE_NUMBER: 'Número de teléfono',
	ADDRESSES: 'Direcciones',
	ADD_ADDRESS: 'Añadir dirección',
	EDIT_ADDRESS: 'Editar dirección',
	DELIVERY_ADDRESS: 'Dirección de entrega',
	ADDRESS_NAME: 'Nombre de la dirección (Ej. Casa, Trabajo)',
	SAVE_ADDRESS: 'Guardar dirección',
	EDIT: 'Editar',
	DELETE: 'Borrar',
	CONTINUE_SHOPPING: 'Continuar comprando',
	CHECK_OUT: 'Pagar',
	SUBTOTAL: 'Subtotal',
	TOTAL: 'Total',
	SHIPPING: 'Envío',
	SELECT_ADDRESS: 'Por favor, selecciona una dirección',
	UNITS: 'Unidades',
	ORDER_SUMMARY: 'Resumen del pedido',
	ALL_FIELDS_REQUIRED: 'Todos los campos son requeridos',
	MESSAGE_SENT_SUCCESSFULLY: 'Mensaje enviado satisfactoriamente',
	AN_ERROR_OCCURRED: 'Ocurrió un error',
	SEND_MESSAGE: 'Enviar mensaje',
	MESSAGE: 'Mensaje',
	CALL_US: 'Llámanos',
	EMAIL_US: 'Envianos un correo',
	CONTACT_US_DESCRIPTION: `Si necesitas ayuda para realizar un
					pedido o tienes alguna pregunta sobre
					nuestra tienda, nuestro equipo está
					disponible para responder a tus
					consultas de lunes a viernes, de 9 am a
					3 pm.`,
	NEWS_LETTER: 'Newsletter',
	NEWS_LETTER_LABEL: 'Introduce tu email',
	NEWS_LETTER_SIGNUP: 'Suscríbete para recibir actualizaciones',
	NEWS_LETTER_BUTTON: 'Suscribirse',
	ORDERING_AND_SHIPPING: 'Pedido y envío',
	THE_FE_STORY: 'Historia de Fina Ejerique',
	CAREERS: 'Careers',
	CUSTOMER_CARE: 'Atención al cliente',
	ABOUT_FE: 'Sobre Fina Ejerique',
	FOLLOW_FE: 'Sigue a Fina Ejerique',
	VIEW_DETAILS: 'Ver detalles',
	ITEMS: 'Artículos',
	ORDER_NUMBER: 'Número de pedido',
	REQUEST_RETURN: 'Solicitar devolución',
	ERROR_ADDRESS_NAME: 'Por favor seleccione un nombre para la dirección',
	ERROR_ADDRESS_FIRST_NAME: 'Por favor agrega tu nombre',
	ERROR_ADDRESS_LAST_NAME: 'Por favor agrega tu apellido',
	ERROR_ADDRESS_COUNTRY: 'por favor seleccione su país',
	ERROR_ADDRESS_CITY: 'Por favor selecciona tu ciudad',
	ERROR_ADDRESS_PROVINCE: 'Por favor selecciona tu provincia',
	ERROR_ADDRESS_POSTAL_CODE: 'Por favor seleccione su código postal',
	ERROR_ADDRESS_STREET_AND_NUMBER: 'Por favor seleccione su dirección',
	ERROR_ADDRESS_PHONE_NUMBER:
		'Por favor seleccione un número de teléfono válido',
	NO_ADDRESSES: 'No hay direcciones',
	BACK: 'Volver',
	PERSONAL_DATA: 'Datos personales',
	BIRTH_DATE: 'Fecha de nacimiento',
	SELECT_SIZE: 'Por favor, selecciona una talla',
	RETURN_ADDRESS: 'Direccion de devolucion',
	DRAFT: 'Borrador',
	PENDING_PAYMENT: 'Pendiente de pago',
	PAID: 'Pagado',
	PAYMENT_FAILED: 'Pago fallido',
	PENDING_REVIEW: 'Pendiente de revisión',
	REVIEWED: 'Revisado',
	SENT_TO_SIMSS: 'Enviado a SIMSS',
	ERROR_SENDING_TO_SIMSS: 'Error al enviar a SIMSS',
	CARRIER_NOTIFIED: 'Transportista notificado',
	ERROR_NOTIFYING_CARRIER: 'Error al notificar al transportista',
	SHIPPED: 'Enviado',
	DELIVERED: 'Entregado',
	COMPLETED: 'Completado',
	CANCELLED: 'Cancelado',
	PASSWORD_CHANGE: 'Cambiar la contraseña',
	PASSWORD_CURRENT: 'Contraseña actual',
	PASSWORD_NEW: 'Nueva contraseña',
	PASSWORD_CONFIRM: 'Confirmar nueva contraseña',
	PASSWORD_DETAILS:
		'La contraseña debe contener: <br />- Al menos 8 caracteres <br />- Al menos una letra mayúscula <br />- Al menos una letra minúscula <br /> - Al menos un número <br />- Al menos un carácter especial (@, #, $, %, etc.)',
	PASSWORD_ERROR_OLD: 'Por favor agrega tu antigua contraseña',
	PASSWORD_ERROR_NEW: 'Por favor agrega tu nueva contraseña',
	PASSWORD_ERROR_CONFIRM: 'Por favor confirma tu nueva contraseña',
	PASSWORD_ERROR_NOT_MATCH: 'Las contraseñas no coinciden',
	PASSWORD_ERROR_INCOMPLETE:
		'La contraseña debe contener al menos 8 caracteres, un número, una letra y un carácter especial.',
	PASSWORD_ERROR_INCORRECT:
		'La contraseña actual no es correcta. Compruébelo por favor.',
	PASSWORD_ERROR_ERROR:
		'Ocurrió un error. Por favor, inténtelo de nuevo más tarde.',
	CART_BAG: 'Carrito',
	CART_EMAIL: 'Email',
	CART_DELIVERY: 'Entrega',
	CART_PAYMENT: 'Pago',
	CHECK_EMAIL_COMMUNICATIONS:
		'Me gustaría recibir comunicaciones exclusivas por correo electrónico de Fina Ejerique.',
	CHECK_TERMS_AND_CONDITIONS:
		'Al realizar su pedido, acepta los términos de uso del sitio web y la política de privacidad de Fina Ejerique.',
	RETURNORDER_CHECK_TERMS_AND_CONDITIONS:
		'Al realizar su devolución, acepta los términos de uso del sitio web y la política de privacidad de Fina Ejerique.',
	PAYMENT_CONFIRM_AND_PAY: 'Confirma y paga',
	PAYMENT_ERROR_TERMS_CONDITIONS: 'Debe aceptar los Términos y Condiciones',
	PAYMENT_ERROR_PAYMENT_METHOD: 'Debes seleccionar un método de pago',
	PAYMENT_ERROR_CREATING_ORDER_ID: 'Error al crear el pedido',
	PAYMENT_ERROR_PAYMENT_METHOD_NOT_FOUND: 'Método de pago no encontrado',
	PAYMENT_ERROR_CREATING_ORDER_RETRY:
		'Error al crear el pedido. Por favor, inténtelo de nuevo más tarde.',
	PAYMENT_CREDIT_CARD: 'Tarjeta de crédito/débito',
	PAYMENT_CREDIT_CARD_DESCRIPTION: 'Utilice su tarjeta para pagar',
	PAYMENT_BIZUM: 'Bizum',
	PAYMENT_BIZUM_DESCRIPTION: 'Paga a través de Bizum',
	SIGNUP_CREATE_ACCOUNT: 'Registrate',
	SIGNUP_EMAIL: 'Dirección de correo electrónico',
	SIGNUP_FIRST_NAME: 'Nombres',
	SIGNUP_LAST_NAME: 'Apellidos',
	SIGNUP_BIRTHDAY: 'Fecha de nacimiento (opcional)',
	SIGNUP_COUNTRY: 'Elige el país',
	SIGNUP_PASSWORD: 'Contraseña',
	SIGNUP_CONFIRM_PASSWORD: 'Confirmar Contraseña',
	SIGNUP_ALREADY_EXISTS: '¿Ya tienes una cuenta?',
	SIGNUP_SIGNIN: 'Iniciar Sesión',
	SIGNUP_ERROR_INVALID_EMAIL: 'Dirección de correo electrónico no válida',
	SIGNUP_ERROR_INVALID_PASSWORD: 'Por favor proporcione una contraseña válida',
	SIGNUP_ERROR_TERMS_CONDITIONS: 'Debe aceptar los Términos y Condiciones',
	SIGNUP_ERROR_PASSWORD_NOT_MATCH: 'Las contraseñas no coinciden',
	SIGNUP_ERROR_COUNTRY: 'Por favor seleccione un país',
	SIGNUP_ERROR_FIRST_NAME: 'Por favor proporciona tu primer nombre',
	SIGNUP_ERROR_LAST_NAME: 'Por favor proporcione su apellido',
	SIGNUP_ERROR_RETRY:
		'Ocurrió un error. Por favor, inténtelo de nuevo más tarde',
	SIGNUP_ERROR_CHECK_FIELDS:
		'Existen errores en los campos. Por favor, revísalos.',
	SIGNIN_CREATE_ACCOUNT: 'Iniciar Sesión',
	SIGNIN_EMAIL: 'Dirección de correo electrónico',
	SIGNIN_PASSWORD: 'Contraseña',
	SIGNIN_PASSWORD_FORGOT: '¿Olvidaste tu contraseña?',
	SIGNIN_ACCOUNT_NOT_EXISTS: '¿No tienes una cuenta?',
	SIGNIN_SIGNUP: 'Registrate',
	SIGNIN_ERROR_INVALID_EMAIL:
		'Por favor, introduce una dirección de correo electrónico válida',
	SIGNIN_ERROR_SIGNIN:
		'Error de inicio de sesion. Por favor, verifique sus credenciales',
	SIGNIN_ERROR_RETRY:
		'Ocurrió un error. Por favor, inténtelo de nuevo más tarde',
	SIGNUP_ERROR_DUPLICATED_EMAIL: 'Éste correo electrónico ya está registrado',
	PRODUCT_CONTACT_US_DESCRIPTION:
		'Si necesitas ayuda para realizar un pedido o tienes alguna pregunta sobre nuestra tienda, nuestro equipo está disponible para responder a tus consultas de lunes a viernes, de 9 am a 3 pm.',
	PRODUCT_CONTACT_US_BUTTON: 'Contactar',
	FOOTER_PAGE_PAYMENT_TYPE_TITLE: 'Formas de pago',
	FOOTER_PAGE_DEVOLUTIONS: 'Las devoluciones son GRATUITAS.',
	FOOTER_PAGE_PAYMENT_TYPE_DESCRIPTION:
		'Puedes utilizar los siguientes medios de pago: Visa, Visa Electron, Mastercard, Maestro, JCB y UPAY.',
	FOOTER_PAGE_PAYMENT_TYPE_DESCRIPTION_L1: '•  La tarjeta no esté caducada.',
	FOOTER_PAGE_PAYMENT_TYPE_DESCRIPTION_L2:
		'•  Que tu tarjeta no exceda la fecha de validez.',
	FOOTER_PAGE_PAYMENT_TYPE_DESCRIPTION_L3:
		'•  Puede que se haya alcanzado el límite de la tarjeta. Consulta con tu banco que la tarjeta no haya excedido el importe permitido para efectuar compras.',
	FOOTER_PAGE_PAYMENT_TYPE_DESCRIPTION_L4:
		'•  Comprueba que has rellenado correctamente todos los campos necesarios.',
	FOOTER_PAGE_INVOICE_QUESTION:
		'¿Puedo obtener una factura a nombre de mi empresa?',
	FOOTER_PAGE_INVOICE_ANSWER:
		'Sí. Solamente tienes que marcar la opción de «empresa» en datos personales y cubrir los datos fiscales que te pedimos.',
	FOOTER_PAGE_SECURITY_TITLE: 'Seguridad',
	FOOTER_PAGE_SECURITY_DESCRIPTION:
		'Para Fina Ejerique, la seguridad en las compras online es fundamental. Todas las transacciones se llevan a cabo a través de sistemas de pago seguros y los datos confidenciales del pago son transmitidos directamente y de forma encriptada (SSL) a la entidad. Contamos con los certificados SSL de Sectigo RSA Domain Validation Secure Server CA y con los certificados de seguridad de transacciones online: Verified by VISA, MasterCard SecureCode y American Express Safekey',
	FOOTER_PAGE_DELIVERY: 'Envíos',
	DELIVERY_TITLE: 'ENTREGA A DOMICILIO GRATUITA A PARTIR DE 50€',
	DELIVERY_DESCRIPTION_1:
		'Los <b>plazos de entrega</b> van de 1 a 3 días laborables en la península (1 o 2 días más en las Islas Baleares). Envíos unicamente a España y Portugal. No se realizan envíos a las Islas Canarias.',
	DELIVERY_DESCRIPTION_2:
		'<b>Los plazos de entrega son para los </b> pedidos realizados de lunes a viernes antes de las 09.00h am. Después de esa hora, el plazo comenzará al siguiente día hábil. En periodos especiales como Black Friday o Rebajas, el plazo de envío puede ser superior al habitual.',
	RETURNORDER_DEVOLUTION_N: 'Nº de devolución',
	RETURNORDER_ORDER_N: 'Numero del Pedido',
	RETURNORDER_BUY_DATE: 'Fecha de Compra',
	RETURNORDER_PRODUCTS: 'Artículos',
	RETURNORDER_SUBTOTAL: 'Subtotal',
	RETURNORDER_TOTAL: 'Total',
	RETURNORDER_SHIPPING: 'Envío',
	RETURNORDER_CONTACT_SUPPORT: 'Error. Pongase en contacto con soporte tecnico',
	RETURNORDER_NO_SELECTED_PRODUCTS: 'No hay productos seleccionados',
	RETURNORDER_SELECT_TERMS_CONDITIONS:
		'Tienes que aceptar los Terminos y Condiciones para poder seguir.',
	RETURNORDER_FIRST_TIME_PREVIEW_MESSAGE:
		'La solicitud de devolución ha sido registrada',
	NEWS_LETTER_SUCCESS: 'Gracias por suscribirte',
	NEWS_LETTER_ERROR:
		'Error al suscribirte. Por favor, inténtalo de nuevo más tarde',
	NEWS_LETTER_ALREADY_SUSCRIBED: 'Ya estás suscrito',
	PASSWORD_RECOVERY: 'Recuperar contraseña',
	PASSWORD_RECOVERY_DESCRIPTION:
		'Introduce tu email y te enviaremos un correo con la nueva contraseña.',
	PASSWORD_RECOVERY_EMAIL: 'Email',
	PASSWORD_RECOVERY_ERROR_INVALID_EMAIL: 'Email no válido',
	PASSWORD_RECOVERY_ERROR_RETRY:
		'Ocurrió un error. Por favor, inténtalo de nuevo más tarde',
	PASSWORD_RECOVERY_ERROR_EMAIL_NOT_FOUND: 'El email no está registrado',
	PASSWORD_RECOVERY_ERROR_EMAIL_NOT_SENT:
		'Error al enviar el email. Por favor, inténtalo de nuevo más tarde',
	PASSWORD_RECOVERY_SUCCESS: 'Hemos enviado un email la nueva contraseña',
	PASSWORD_RECOVERY_ERROR:
		'Ocurrió un error. Por favor, inténtalo de nuevo más tarde',
	PASSWORD_RECOVERY_ERROR_EMAIL: 'Por favor, introduce un email válido',
	PASSWORD_RECOVERY_SUBMIT: 'Enviar',
	Draft: 'Borrador',
	'Pending payment': 'Pendiente de pago',
	Paid: 'Pagado',
	'Payment failed': 'Pago fallido',
	'Paid pending review': 'Pagado pendiente de revisión',
	Reviewed: 'Revisado',
	'Sent to SIMSS': 'Enviado a SIMSS',
	'Error sending to SIMSS': 'Error al enviar a SIMSS',
	'Carrier notified': 'Transportista notificado',
	'Error notifying carrier': 'Error al notificar al transportista',
	Shipped: 'Enviado',
	Delivered: 'Entregado',
	Completed: 'Completado',
	Cancelled: 'Cancelado',
	PAGE_NOT_FOUND: 'Página no encontrada',
	NO_PRODUCTS_FOUND: 'No se encontraron productos para esta busqueda',
}

export const es: ResourceLanguage = {
	translation: translations,
}

export default es
