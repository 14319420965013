import { MailOutline, PersonOutlineOutlined, Phone } from '@mui/icons-material'
import { Box, Link, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Translations } from '../../../../../translations/translations.enum'

export const MobileHeaderSectionCustomerService = () => {
	const { t } = useTranslation()
	return (
		<Box p={'0px 8px 8px'}>
			<Link
				underline='none'
				sx={{
					alignContent: 'center',
					display: 'flex',
					justifyContent: 'flex-start',
					alignItems: 'center',
					padding: '8px 16px',
				}}
			>
				<Typography
					fontWeight={'bold'}
					lineHeight={'1.75'}
				>
					{t(Translations.CUSTOMER_SERVICE)}
				</Typography>
			</Link>
			<Link
				underline='none'
				sx={{
					alignContent: 'center',
					display: 'flex',
					justifyContent: 'flex-start',
					alignItems: 'center',
					padding: '8px 16px',
				}}
			>
				<Phone />
				<Typography
					pl={'18px'}
					lineHeight={'1.75'}
				>
					+34 96 313 51 67
				</Typography>
			</Link>
			<Link
				underline='none'
				sx={{
					alignContent: 'center',
					display: 'flex',
					justifyContent: 'flex-start',
					alignItems: 'center',
					padding: '8px 16px',
				}}
			>
				<MailOutline />
				<Typography
					pl={'18px'}
					lineHeight={'1.75'}
				>
					hello@finaejerique.com
				</Typography>
			</Link>
		</Box>
	)
}
