import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
} from '@mui/material'
import '../../../assets/scss/product.scss'
import '../../../assets/scss/color.scss'
import '../../../assets/scss/functions.scss'
import { BlackButton } from '../black-button'
import { ProductAccordion } from '../accordion'
import React, { useEffect, useState } from 'react'
import { IProduct, IProductVersion } from '..'
import { VariantPicker } from '../variant-picker'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Translations } from '../../../translations/translations.enum'
import { useTranslation } from 'react-i18next'
import { IRoute } from '../../../interfaces/route.interface'
import {
	handleGATagLoadProduct,
	handleGATagAddToCartClick,
} from '../../utils/google-analytics'
import { getPriceComponent } from '../../../utils/price-component'
import { SizeChartTable } from '../../footer/pages/components/size-chart-table'
import {
	handleMetaTagAddToCartClick,
	handleMetaTagLoadProduct,
} from '../../utils/meta-business'
import { getEntityEID, getUrlParamWithEID } from '../../utils/base64'
import { WishlistButton } from '../../utils/wishlist-button'

export interface IMobileProductBodyProps extends IRoute {
	product?: IProduct
	updateCart: (
		modelId: number,
		quantity: number,
		variantId: number | 'default'
	) => void
}

const MobileProductBody = (props: IMobileProductBodyProps) => {
	const { id, language } = useParams()
	const [searchParams] = useSearchParams()
	const { t } = useTranslation()
	const navigate = useNavigate()
	const [productId, setProductId] = useState<number | null>(
		getEntityEID(searchParams) || Number(id)
	)

	const [orderedVersions, setOrderedVersions] = useState<IProduct['versions']>(
		[]
	)
	const [activeOptionSize, setActiveOptionSize] = useState<number | null>(null)
	const [showSizeError, setShowSizeError] = useState<boolean>(false)
	const [allOutOfStock, setAllOutOfStock] = useState<boolean>(false)

	const [open, setOpen] = useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	const selectOptionSize = (id: number) => setActiveOptionSize(id)

	useEffect(() => {
		setProductId(getEntityEID(searchParams) || Number(id))
		const currentColor: IProductVersion = {
			modelId: productId || 0,
			color: props.product?.variants ? props.product?.variants[0].color : '',
		}
		console.log('currentColor', currentColor, props.product)
		const versions = [...(props.product?.versions || []), currentColor]

		const sortedVersions =
			versions.sort(function (a, b) {
				const aId = `${a.color.toLowerCase()}-${a.modelId}`
				const bId = `${b.color.toLowerCase()}-${b.modelId}`
				if (aId < bId) {
					return -1
				}
				if (aId > bId) {
					return 1
				}
				return 0
			}) || []

		setOrderedVersions(sortedVersions)
		if (props.product) {
			handleGATagLoadProduct(props.product)
			handleMetaTagLoadProduct(props.product)
		}

		const isAllOutStock =
			props.product?.variants?.every((variant) => variant.stock === 0) ?? true

		setAllOutOfStock(isAllOutStock)
	}, [props.product, productId, searchParams])

	const addProductToCart = () => {
		props.updateCart(props.product?.id || 0, 1, activeOptionSize || 'default')
	}

	return (
		<>
			<Typography
				variant='h1'
				className='product__name'
				style={{
					fontFamily: 'Antic',
				}}
			>
				{props.product?.name || ''}
			</Typography>

			{getPriceComponent(
				'1.75rem',
				props.product?.price || 0,
				props.product?.priceAfterDiscount
			)}
			{props.product?.variants ? (
				<>
					<Box>
						<Typography
							sx={{
								fontSize: '1.2rem',
								fontWeight: '500',
								margin: '20px 0',
								textAlign: 'left',
								fontFamily: 'Montserrat',
							}}
						>
							{t(Translations.COLOR)}
						</Typography>
						<Box
							sx={{
								display: 'flex',
								flexWrap: 'wrap',
								justifyContent: 'flex-start',
								alignItems: 'center',
								margin: '0 -1rem',
							}}
						>
							{orderedVersions?.map((version) => (
								<Link
									to={`/${language}/${getUrlParamWithEID(
										props.product?.name || '',
										'product',
										version.modelId
									)}`}
									style={{
										textDecoration: 'none',
										color: '#000',
									}}
								>
									<VariantPicker
										color={version.color}
										modelId={version.modelId}
										active={version.modelId === props.product?.id}
										stock={1}
									/>
								</Link>
							))}
						</Box>
					</Box>

					<Box>
						<Box
							sx={{
								display: 'flex',
								flexWrap: 'wrap',
								justifyContent: 'space-between',
							}}
						>
							<Typography
								sx={{
									fontSize: '1.2rem',
									fontWeight: '500',
									margin: '20px 0',
									textAlign: 'left',
									fontFamily: 'Montserrat',
								}}
							>
								{t(Translations.SIZE)}
							</Typography>
							<Typography
								sx={{
									fontSize: '1rem',
									fontWeight: '500',
									margin: '20px 0',
									textAlign: 'left',
									fontFamily: 'Montserrat',
									textDecoration: 'underline',
									cursor: 'pointer',
								}}
								onClick={handleClickOpen}
							>
								{t(Translations.SIZE_CHART)}
							</Typography>
						</Box>
						<Box
							sx={{
								display: 'flex',
								flexWrap: 'wrap',
								justifyContent: 'flex-start',
								alignItems: 'center',
								margin: '0 -1rem',
							}}
						>
							{props.product?.variants?.map((variant) => (
								<VariantPicker
									size={variant.size}
									variantId={variant.variantId}
									stock={variant.stock}
									onSelect={selectOptionSize}
									active={activeOptionSize === variant.variantId}
								/>
							))}
						</Box>
					</Box>
				</>
			) : (
				<> </>
			)}

			<Box margin={'20px 0'}></Box>
			{showSizeError && (
				<Typography
					sx={{
						fontSize: '1.2rem',
						fontWeight: '500',
						margin: '20px 0',
						textAlign: 'left',
						fontFamily: 'Montserrat',
						color: 'red',
					}}
				>
					{t(Translations.SELECT_SIZE)}
				</Typography>
			)}
			<BlackButton
				text={Translations.ADD_TO_CART}
				disabled={allOutOfStock}
				onClick={() => {
					if (
						!props.product ||
						(props.product?.variants?.length && !activeOptionSize)
					) {
						setShowSizeError(true)
					} else {
						setShowSizeError(false)
						addProductToCart()
						handleGATagAddToCartClick(props.product)
						handleMetaTagAddToCartClick(props.product)
						navigate(`/${language}/shopping-bag`)
					}
				}}
			/>
			{props.product?.id && <WishlistButton id={props.product?.id} />}
			<ProductAccordion
				product={props.product}
				isMobile={props.isMobile}
			/>

			<Dialog
				open={open}
				onClose={handleClose}
				fullWidth
				maxWidth='sm'
			>
				<DialogTitle>{t(Translations.SIZE_CHART)}</DialogTitle>
				<DialogContent>
					<SizeChartTable />
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleClose}
						color='primary'
					>
						X
					</Button>
				</DialogActions>
			</Dialog>
		</>
	)
}

export default React.memo(MobileProductBody) // It avoids to redraw the component if the props are the same
