import { useEffect, useRef, useState } from 'react'
import { Grid, Box } from '@mui/material'
import MobileProductBody from '../mobile/product-body'

import { IRoute } from '../../../interfaces/route.interface'
import { IProduct } from '..'
import { LazyImageUnknownHeight } from '../../lazy-image/lazy-image-unknown-height'
import { ProductQuantity } from '../../../interfaces/cart.interface'
import ProductZoom from '../zoom.product'
import ImageModal from '../zoom-modal.product'
import { handleGATagViewItem } from '../../utils/google-analytics'
import { handleMetaTagViewItem } from '../../utils/meta-business'
import { Helmet } from 'react-helmet-async'

export interface IDesktopProductBodyProps extends IRoute {
	product?: IProduct
	updateCart: (
		modelId: number,
		product: ProductQuantity,
		variantId: number | 'default'
	) => void
}

export const DesktopProductBody = (props: IDesktopProductBodyProps) => {
	const [open, setOpen] = useState(false)
	const [modalImageIndex, setModalImageIndex] = useState(0)
	const [isSticky, setIsSticky] = useState(false)
	const [stickyHeight, setStickyHeight] = useState(0)

	const elementRef = useRef<HTMLDivElement>(null)

	const handleScroll = () => {
		const currentScrollHeight = window.scrollY
		const trigger = elementRef.current?.getBoundingClientRect().height || 0
		const scrollHeightTrigger = currentScrollHeight + window.innerHeight

		if (scrollHeightTrigger <= trigger) {
			setIsSticky(false)
			setStickyHeight(0)
		} else {
			setIsSticky(true)
			setStickyHeight(-trigger + window.innerHeight)
		}
	}

	const onLoad = () => window.scrollTo(0, 0)
	window.addEventListener('load', onLoad)

	window.addEventListener('scroll', handleScroll)

	useEffect(() => {
		window.removeEventListener('load', onLoad)
		window.removeEventListener('scroll', handleScroll)

		if (props.product) {
			handleGATagViewItem(props.product)
			handleMetaTagViewItem(props.product)
		}
	}, [])

	return (
		<Box sx={{ width: '100%', display: 'flex' }}>
			<Helmet>
				<title>{props.product?.name || ''} - Fina Ejerique</title>
				<meta
					name='description'
					content={props.product?.description || ''}
				/>
				<meta
					name='keywords'
					content='finaejerique, fina ejerique, ropa infantil, moda infantil, ropa de bebe, moda de bebe, ropa de niño, moda de niño, ropa de niña, moda de niña, ropa de recien nacido, moda de recien nacido, ropa de bebe online, moda de bebe online, ropa de niño online, moda de niño online, ropa de niña online, moda de niña online, ropa de recien nacido online, moda de recien nacido online'
				/>
			</Helmet>
			<ImageModal
				open={open}
				setOpen={setOpen}
				images={props.product?.images || []}
				modalImageIndex={modalImageIndex}
				setModalImageIndex={setModalImageIndex}
			/>
			<Grid container>
				<Grid
					item
					xs={12}
					md={6}
					sx={{
						...(props.isMobile ? { display: 'none' } : {}),
					}}
				>
					{props.product?.images?.map((image, index) => (
						<LazyImageUnknownHeight
							imageSrc={`${process.env.REACT_APP_IMAGE_URL}/${process.env.REACT_APP_PRODUCT_IMAGE_PATH}/${image}`}
							loadPlaceholderSize='10%'
							loadPlaceholderMargin='60%'
							onClick={() => {
								setModalImageIndex(index)
								setOpen(true)
							}}
						/>
					))}
				</Grid>

				<Grid
					item
					xs={12}
					md={6}
				>
					<Box
						ref={elementRef}
						className='product'
						margin={'10px 18px 0'}
						sx={{
							'@media only screen and (min-width: 769px)': {
								margin: '0 auto',
								maxWidth: '462px',
								position: isSticky ? 'sticky' : 'relative',
								top: stickyHeight,
								padding: '0 20px',
								paddingTop: '43px',
								paddingBottom: '75px',
							},
						}}
					>
						<MobileProductBody
							product={props.product}
							updateCart={props.updateCart}
							isMobile={props.isMobile}
						></MobileProductBody>
					</Box>
				</Grid>
			</Grid>
		</Box>
	)
}
