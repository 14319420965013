import { useEffect, useState } from 'react'
import { IRoute } from '../../interfaces/route.interface'
import { Grid, Typography } from '@mui/material'
import { IProduct } from '../product'
import { t } from 'i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Translations } from '../../translations/translations.enum'
import { CategoryGridItem } from '../category/grid-item'
import { RequestManager } from '../utils'
import { useParams } from 'react-router-dom'

export interface IWishlistProps extends IRoute {}

export function Wishlist(props: IWishlistProps) {
	const { language } = useParams()
	const [products, setProducts] = useState<IProduct[]>([])
	const [pageNumber, setPageNumber] = useState(1)
	const [hasMoreData, setHasMoreData] = useState(true)
	const pageSize = 8

	const fetchData = async () => {
		const response = await RequestManager(
			'GET',
			`wishlist?pageSize=${pageSize}&pageNumber=${pageNumber}&language=${language}`
		)
		const result = response.data
		const currentData = [...products, ...result]

		setPageNumber(pageNumber + 1)
		setProducts(currentData)
		if (result.length < pageSize) {
			setHasMoreData(false)
		}
	}

	useEffect(() => {
		fetchData()
	}, [])

	return (
		<>
			<InfiniteScroll
				key={'wishlist-infinite-scroll'}
				dataLength={products.length}
				next={fetchData}
				hasMore={hasMoreData} // cambia esto dependiendo de si hay más elementos para cargar
				loader={<h4>{t(Translations.LOADING)}...</h4>}
			>
				<Grid
					container
					sx={{
						marginTop: '10px',
					}}
				>
					{products?.map((item, index) => (
						<CategoryGridItem
							key={item.id}
							product={item}
							isMobile={props.isMobile}
							hidePrice={false}
						/>
					))}
				</Grid>
			</InfiniteScroll>
		</>
	)
}
