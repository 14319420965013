import { Box, Typography } from '@mui/material'

export function AccordionDescription(props: { description: string }) {
	return (
		<Box m={'5% 0 7.5%'}>
			<Typography
				variant='h2'
				className='product__description'
			>
				{props.description}
			</Typography>
		</Box>
	)
}
