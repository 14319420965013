import { StarOutline, StarRate } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import { Translations } from '../../translations/translations.enum'
import { useTranslation } from 'react-i18next'
import Cookies from 'js-cookie'
import { useState, useEffect } from 'react'
import {
	RequestManager,
	isRequestSuccessfull,
} from '../../utils/request-manager'

export interface IWishlistButtonProps {
	id: number
}

export const WishlistButton = ({ id }: IWishlistButtonProps) => {
	const { t } = useTranslation()

	const [isWishlisted, setIsWishlisted] = useState<boolean>(false)

	const checkWishlisted = async () => {
		if (Cookies.get('accessToken')) {
			const response = await RequestManager('GET', `wishlist/model/${id}`)
			setIsWishlisted(response.data)
		}
	}

	const onClick = async () => {
		const action = isWishlisted ? 'DELETE' : 'POST'
		const response = await RequestManager(action, `wishlist/model/${id}`)
		setIsWishlisted(
			isRequestSuccessfull(response.status) ? !isWishlisted : isWishlisted
		)
	}

	useEffect(() => {
		checkWishlisted()
	}, [id])

	return (
		<Button
			sx={{
				display: Cookies.get('accessToken') === undefined ? 'none' : 'flex',
				borderRadius: '0px',
				backgroundColor: 'secondary.main',
				color: 'primary.main',
				boxShadow: 'none',
				width: '100%',
				'&:hover': {
					boxShadow: 'none',
					backgroundColor: 'secondary.main',
				},
			}}
			onClick={onClick}
		>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
				}}
			>
				{isWishlisted ? (
					<StarRate
						sx={{
							fontSize: '22px',
							margin: '-4px 10px 0 0',
						}}
					/>
				) : (
					<StarOutline
						sx={{
							fontSize: '22px',
							margin: '-4px 10px 0 0',
						}}
					/>
				)}
				<Typography
					sx={{
						fontSize: '0.785rem',
						fontWeight: 'bold',
						padding: '.85rem 0',
						letterSpacing: '.25rem',
						transition: 'color .2s ease-in-out',
						'&:hover': {
							color: '#afafaf',
						},
					}}
				>
					{t(Translations.WISHLIST)}
				</Typography>
			</Box>
		</Button>
	)
}
