import {
	Box,
	Container,
	List,
	ListItem,
	ListItemText,
	Paper,
	Typography,
} from '@mui/material'
import { IRoute } from '../../../interfaces/route.interface'

export const TermsAndConditionsPage = (props: IRoute) => {
	return (
		<Container>
			<Paper
				elevation={3}
				style={{
					padding: '4rem',
					margin: '20px 0',
				}}
			>
				<Typography
					variant='h4'
					gutterBottom
				>
					AVISO LEGAL
				</Typography>

				<Typography
					variant='h5'
					gutterBottom
				>
					1. OBJETO
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					El presente aviso legal regula el uso y utilización del sitio web{' '}
					<a href='http://www.finaejerique.com'>www.finaejerique.com</a>, del
					que es titular GLOBOTEX, S.L. (en adelante, EL PROPIETARIO DE LA WEB).
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					La navegación por el sitio web de EL PROPIETARIO DE LA WEB le atribuye
					la condición de USUARIO del mismo y conlleva su aceptación plena y sin
					reservas de todas y cada una de las condiciones publicadas en este
					aviso legal, advirtiendo de que dichas condiciones podrán ser
					modificadas sin notificación previa por parte de EL PROPIETARIO DE LA
					WEB, en cuyo caso se procederá a su publicación y aviso con la máxima
					antelación posible.
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					Por ello es recomendable leer atentamente su contenido en caso de
					desear acceder y hacer uso de la información y de los servicios
					ofrecidos desde este sitio web.
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					El usuario, además, se obliga a hacer un uso correcto del sitio web de
					conformidad con las leyes, la buena fe, el orden público, los usos del
					tráfico y el presente Aviso Legal, y responderá frente a EL
					PROPIETARIO DE LA WEB o frente a terceros, de cualesquiera daños y
					perjuicios que pudieran causarse como consecuencia del incumplimiento
					de dicha obligación.
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					Cualquier utilización distinta a la autorizada está expresamente
					prohibida, pudiendo EL PROPIETARIO DE LA WEB denegar o retirar el
					acceso y su uso en cualquier momento.
				</Typography>

				<Typography
					variant='h5'
					gutterBottom
				>
					2. IDENTIFICACIÓN
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					EL PROPIETARIO DE LA WEB, en cumplimiento de la Ley 34/2002, de 11 de
					julio, de Servicios de la Sociedad de la Información y de Comercio
					Electrónico, le informa de que:
				</Typography>
				<List>
					<ListItem>
						<ListItemText primary='Denominación social GLOBOTEX, S.L.' />
					</ListItem>
					<ListItem>
						<ListItemText primary='CIF B97230692' />
					</ListItem>
					<ListItem>
						<ListItemText primary='Domicilio social C/ ALMACIL, 6 Y 8 BAJOS - 46920 - MISLATA - VALENCIA' />
					</ListItem>
					<ListItem>
						<ListItemText primary='Registro Mercantil VALENCIA T 7228, L 4530, F 208, S 8, H V 84497, I/A 5' />
					</ListItem>
				</List>

				<Typography
					variant='h5'
					gutterBottom
				>
					3. COMUNICACIONES
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					Para comunicarse con nosotros, ponemos a su disposición diferentes
					medios de contacto que detallamos a continuación:
				</Typography>
				<List>
					<ListItem>
						<ListItemText primary='Tfno. 963135167' />
					</ListItem>
					<ListItem>
						<ListItemText primary='Email legal@finaejerique.es' />
					</ListItem>
				</List>

				<Typography
					variant='body1'
					paragraph
				>
					Todas las notificaciones y comunicaciones entre los usuarios y
					PROPIETARIO DE LA WEB se considerarán eficaces, a todos los efectos,
					cuando se realicen a través de cualquier medio de los detallados
					anteriormente.
				</Typography>

				<Typography
					variant='h5'
					gutterBottom
				>
					4. CONDICIONES DE ACCESO Y UTILIZACIÓN
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					El sitio web y sus servicios son de acceso libre y gratuito. No
					obstante, PROPIETARIO DE LA WEB puede condicionar la utilización de
					algunos de los servicios ofrecidos en su web a la previa
					cumplimentación del correspondiente formulario.
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					El usuario garantiza la autenticidad y actualidad de todos aquellos
					datos que comunique a PROPIETARIO DE LA WEB y será el único
					responsable de las manifestaciones falsas o inexactas que realice.
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					El usuario se compromete expresamente a hacer un uso adecuado de los
					contenidos y servicios de PROPIETARIO DE LA WEB y a no emplearlos
					para, entre otros:
				</Typography>
				<List>
					<ListItem>
						<ListItemText primary='a) Difundir contenidos delictivos, violentos, pornográficos, racistas, xenófobos, ofensivos, de apología del terrorismo o, en general, contrarios a la ley o al orden público.' />
					</ListItem>
					<ListItem>
						<ListItemText primary='b) Introducir en la red virus informáticos o realizar actuaciones susceptibles de alterar, estropear, interrumpir o generar errores o daños en los documentos electrónicos, datos o sistemas físicos y lógicos de PROPIETARIO DE LA WEB o de terceras personas.' />
					</ListItem>
					<ListItem>
						<ListItemText primary='c) Intentar acceder a las cuentas de correo electrónico de otros usuarios o a áreas restringidas de los sistemas informáticos de PROPIETARIO DE LA WEB o de terceros y, en su caso, extraer información.' />
					</ListItem>
					<ListItem>
						<ListItemText primary='d) Vulnerar los derechos de propiedad intelectual o industrial, así como violar la confidencialidad de la información de PROPIETARIO DE LA WEB o de terceros.' />
					</ListItem>
					<ListItem>
						<ListItemText primary='e) Suplantar la identidad de cualquier otro usuario.' />
					</ListItem>
					<ListItem>
						<ListItemText primary='f) Reproducir, copiar, distribuir, poner a disposición de, o cualquier otra forma de comunicación pública, transformar o modificar los contenidos, a menos que se cuente con la autorización del titular de los correspondientes derechos o ello resulte legalmente permitido.' />
					</ListItem>
					<ListItem>
						<ListItemText primary='g) Recabar datos con finalidad publicitaria y de remitir publicidad de cualquier clase y comunicaciones con fines de venta u otras de naturaleza comercial sin que medie su previa solicitud o consentimiento.' />
					</ListItem>
				</List>

				<Typography
					variant='body1'
					paragraph
				>
					Todos los contenidos del sitio web, como textos, fotografías,
					gráficos, imágenes, iconos, tecnología, software, así como su diseño
					gráfico y códigos fuente, constituyen una obra cuya propiedad
					pertenece a PROPIETARIO DE LA WEB, sin que puedan entenderse cedidos
					al usuario ninguno de los derechos de explotación sobre los mismos más
					allá de lo estrictamente necesario para el correcto uso de la web.
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					Asimismo, todas las marcas, nombres comerciales o signos distintivos
					de cualquier clase que aparecen en el sitio web son propiedad de
					PROPIETARIO DE LA WEB, sin que pueda entenderse que el uso o acceso al
					mismo atribuya al usuario derecho alguno sobre los mismos.
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					La distribución, modificación, cesión o comunicación pública de los
					contenidos y cualquier otro acto que no haya sido expresamente
					autorizado por el titular de los derechos de explotación quedan
					prohibidos.
				</Typography>

				<Typography
					variant='h5'
					gutterBottom
				>
					4.1. EXCLUSIÓN DE GARANTÍAS Y DE RESPONSABILIDAD EN EL ACCESO Y LA
					UTILIZACIÓN
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					El contenido del presente sitio web es de carácter general y tiene una
					finalidad meramente informativa, sin que se garantice plenamente el
					acceso a todos los contenidos, ni su exhaustividad, corrección,
					vigencia o actualidad, ni su idoneidad o utilidad para un objetivo
					específico.
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					PROPIETARIO DE LA WEB excluye, hasta donde permite el ordenamiento
					jurídico, cualquier responsabilidad por los daños y perjuicios de toda
					naturaleza derivados de:
				</Typography>
				<List>
					<ListItem>
						<ListItemText primary='a) La imposibilidad de acceso al sitio web o la falta de veracidad, exactitud, exhaustividad y/o actualidad de los contenidos, así como la existencia de vicios y defectos de toda clase de los contenidos transmitidos, difundidos, almacenados, puestos a disposición, a los que se haya accedido a través del sitio web o de los servicios que se ofrecen.' />
					</ListItem>
					<ListItem>
						<ListItemText primary='b) La presencia de virus o de otros elementos en los contenidos que puedan producir alteraciones en los sistemas informáticos, documentos electrónicos o datos de los usuarios.' />
					</ListItem>
					<ListItem>
						<ListItemText primary='c) El incumplimiento de las leyes, la buena fe, el orden público, los usos del tráfico y el presente aviso legal como consecuencia del uso incorrecto del sitio web.' />
					</ListItem>
				</List>

				<Typography
					variant='body1'
					paragraph
				>
					Asimismo, PROPIETARIO DE LA WEB declina cualquier responsabilidad
					respecto a la información que se halle fuera de esta web y no sea
					gestionada directamente por nuestro webmaster.
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					La función de los links que aparecen en esta web es exclusivamente la
					de informar al usuario sobre la existencia de otras fuentes
					susceptibles de ampliar los contenidos que ofrece este sitio web.
					PROPIETARIO DE LA WEB no garantiza ni se responsabiliza del
					funcionamiento o accesibilidad de los sitios enlazados; ni sugiere,
					invita o recomienda la visita a los mismos, por lo que tampoco será
					responsable del resultado obtenido.
				</Typography>

				<Typography
					variant='h5'
					gutterBottom
				>
					4.2. PROCEDIMIENTO EN CASO DE REALIZACIÓN DE ACTIVIDADES DE CARÁCTER
					ILÍCITO
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					En el caso de que cualquier usuario o un tercero considere que existen
					hechos o circunstancias que revelen el carácter ilícito de la
					utilización de cualquier contenido y/o de la realización de cualquier
					actividad en las páginas web incluidas o accesibles a través del sitio
					web, deberá enviar una notificación a PROPIETARIO DE LA WEB
					identificándose debidamente y especificando las supuestas
					infracciones.
				</Typography>

				<Typography
					variant='h5'
					gutterBottom
				>
					4.3. PUBLICACIONES
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					La información administrativa facilitada a través del sitio web no
					sustituye la publicidad legal de las leyes, normativas, planes,
					disposiciones generales y actos que tengan que ser publicados
					formalmente a los diarios oficiales de las administraciones públicas,
					que constituyen el único instrumento que da fe de su autenticidad y
					contenido. La información disponible en este sitio web debe entenderse
					como una guía sin propósito de validez legal.
				</Typography>

				<Typography
					variant='h5'
					gutterBottom
				>
					5. LEGISLACIÓN APLICABLE
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					Las condiciones presentes se regirán por la legislación española
					vigente. La lengua utilizada será el Castellano.
				</Typography>
			</Paper>
		</Container>
	)
}
