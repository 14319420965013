import {
	AccordionProps,
	AccordionSummaryProps,
	Box,
	Typography,
	styled,
} from '@mui/material'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import { IProduct } from '.'
import { AccordionDelivery } from './accordion-components/delivery'
import { AccordionDescription } from './accordion-components/description'
import { AccordionDetails } from './accordion-components/details'
import { AccordionContacUs } from './accordion-components/contact-us'
import { Translations } from '../../translations/translations.enum'
import { useTranslation } from 'react-i18next'
import { IRoute } from '../../interfaces/route.interface'

export interface IProductAccordionProps extends IRoute {
	product?: IProduct
}

export const ProductAccordion = (props: IProductAccordionProps) => {
	const { t } = useTranslation()
	let itemLabels = [
		{
			label: Translations.DETAILS,
			child: <AccordionDetails product={props.product} />,
		},
		{
			label: Translations.DELIVERY,
			child: <AccordionDelivery />,
		},
		{
			label: Translations.CONTACT_US,
			child: <AccordionContacUs isMobile={props.isMobile} />,
		},
	]

	if (props.product?.description) {
		const description = {
			label: Translations.DESCRIPTION,
			child: <AccordionDescription description={props.product?.description} />,
		}
		itemLabels = [description, ...itemLabels]
	}

	const Accordion = styled((props: AccordionProps) => (
		<MuiAccordion
			disableGutters
			elevation={0}
			square
			{...props}
		/>
	))(({ theme }) => ({
		'&:not(:last-child)': {
			borderBottom: 0,
		},
		'&:before': {
			display: 'none',
		},
	}))

	const AccordionSummary = styled((props: AccordionSummaryProps) => (
		<MuiAccordionSummary
			{...props}
			expandIcon={
				<ArrowForwardIosSharpIcon
					sx={{
						fontSize: '0.9rem',
						transform: 'rotate(90deg)',
						color: '#000',
					}}
				/>
			}
		/>
	))(({ theme }) => ({
		backgroundColor:
			theme.palette.mode === 'dark'
				? 'rgba(255, 255, 255, .05)'
				: 'rgba(0, 0, 0, 0)',
		flexDirection: 'row',
		'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
			transform: 'rotate(180deg)',
		},
		'& .MuiAccordionSummary-content': {
			marginLeft: theme.spacing(1),
		},
	}))

	const CustomAccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
		padding: theme.spacing(2),
	}))

	return (
		<Box
			sx={{
				margin: '24px 0',
			}}
		>
			{itemLabels.map((item, index) => (
				<Accordion
					sx={{
						backgroundColor: 'secondary.main',
					}}
				>
					<AccordionSummary
						aria-controls='panel1d-content'
						id='panel1d-header'
						sx={{
							borderTop: '1px solid #e2e2e2',
							padding: 0,
						}}
					>
						<Typography
							typography={{
								fontFamily: 'Bembo',
								fontSize: '1.14286rem',
								color: '#000',
							}}
						>
							{t(item.label)}
						</Typography>
					</AccordionSummary>
					<CustomAccordionDetails
						sx={{
							padding: '0 7.5%',
							margin: 0,
						}}
					>
						{item.child || <p></p>}
					</CustomAccordionDetails>
				</Accordion>
			))}
		</Box>
	)
}
