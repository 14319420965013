import { ResourceLanguage } from 'i18next'
import {
	AccountRoutes,
	AddressErrors,
	AddressTranslations,
	Cart,
	Delivery,
	DeliveryStatusTranslations,
	ErrorTranslations,
	FilterColor,
	FooterPage,
	MenuCategoryTranslations,
	OrderStatusTranslation,
	Password,
	PasswordRecover,
	Payment,
	Product,
	ReturnOrder,
	SignIn,
	SignUp,
	Translations,
} from './translations.enum'

const translations: Record<
	| Translations
	| MenuCategoryTranslations
	| AccountRoutes
	| FilterColor
	| AddressTranslations
	| AddressErrors
	| Password
	| Cart
	| Payment
	| SignUp
	| SignIn
	| Product
	| FooterPage
	| Delivery
	| ReturnOrder
	| DeliveryStatusTranslations
	| PasswordRecover
	| OrderStatusTranslation
	| ErrorTranslations,
	string
> = {
	SEARCH: 'Search',
	COOKIE_POLICY: 'Cookie Policy',
	TERMS_CONDITIONS: 'Terms & Conditions',
	PRIVACY_POLICY: 'Privacy Policy',
	FAQS: 'FAQs',
	SPANISH: 'Spanish',
	ENGLISH: 'English',
	ITALIAN: 'Italian',
	PORTUGUESE: 'Portuguese',
	SHOPPING_BAG: 'Shopping Bag',
	WISHLIST: 'Wishlist',
	MYFINAEJERIQUE: 'My account',
	CUSTOMER_SERVICE: 'Customer Service',
	NEED_HELP_ORDER: 'Do you need help with your order?',
	COLOR: 'Color',
	SIZE: 'Size',
	SIZE_CHART: 'Size chart',
	REGION: 'Region',
	LANGUAGE: 'Language',
	SHIPPING_TO: 'Shipping to',
	SHIPPING_LOCATION: 'United Kingdom',
	BABY: 'Baby',
	NEW_BORN: 'New Born',
	CHILDREN: 'Children',
	SIGN_IN: 'Sign In',
	SIGN_OUT: 'Sign Out',
	SIGN_UP: 'Sign Up',
	LOADING: 'Loading',
	DESCRIPTION: 'Description',
	DETAILS: 'Details',
	DELIVERY: 'Delivery',
	CONTACT_US: 'Contact Us',
	SIMILAR_PRODUCTS: 'Similar Products',
	YOU_MAY_ALSO_LIKE: 'You may also like',
	ADD_TO_CART: 'Add to Cart',
	CLEAR_FILTERS: 'Clear Filters',
	FILTER: 'Filter',
	APPLY: 'Apply',
	ACCOUNT_OVERVIEW: 'Account overview',
	ORDER_HISTORY: 'Order history',
	ADDRESS_BOOK: 'Address book',
	ADDRESS_EDIT: 'Edit address',
	ADDRESS_NEW: 'New address',
	CHANGE_PASSWORD: 'Change password',
	CONTINUE_TO_PAYMENT: 'Continue to payment',
	ORDER_VIEW: 'View order',
	RED_PALETTE: 'Reds',
	PINK_PALETTE: 'Pinks',
	PURPLE_PALETTE: 'Purple',
	BLUE_PALETTE: 'Blues',
	GREEN_PALETTE: 'Greens',
	YELLOW_PALETTE: 'Yellows',
	ORANGE_PALETTE: 'Oranges',
	BROWN_PALETTE: 'Browns',
	GRAY_PALETTE: 'Grays',
	WHITE_PALETTE: 'Whites',
	BLACK_PALETTE: 'Blacks',
	MY_INFO: 'My account info',
	ORDER_RETURN: 'Order return',
	RETURNS: 'Returns',
	RETURN_VIEW: 'Return',
	ORDER_HISTORY_DESCRIPTION: 'Check your order history',
	ADDRESS_BOOK_DESCRIPTION: 'Manage your addresses',
	PASSWORD_CHANGE_DESCRIPTION: 'Change your password',
	RETURN_DESCRIPTION: 'Manage your returns',
	WISHLIST_DESCRIPTION: "Wish list of Fina Ejerique's products",
	ACCOUNT_OVERVIEW_DESCRIPTION: 'Hi',
	ADDRESS: 'Address',
	ADDRESS_NO_ADDRESSES: 'Create your first address',
	ADDRESS_FIRST_NAME: 'First name',
	ADDRESS_LAST_NAME: 'Last name',
	ADDRESS_COUNTRY: 'Country',
	ADDRESS_CITY: 'City',
	ADDRESS_PROVINCE: 'Province',
	ADDRESS_POSTAL_CODE: 'Postal code',
	ADDRESS_STREET_AND_NUMBER: 'Street and number',
	ADDRESS_ADDITIONAL_DATA: 'Floor/Door or other (Optional)',
	ADDRESS_PHONE_NUMBER: 'Phone number',
	ADDRESSES: 'Addresses',
	ADD_ADDRESS: 'Add address',
	EDIT_ADDRESS: 'Edit address',
	DELIVERY_ADDRESS: 'Delivery address',
	ADDRESS_NAME: 'Name of the address (Ex. House, Work)',
	SAVE_ADDRESS: 'Save address',
	EDIT: 'Edit',
	DELETE: 'Delete',
	CONTINUE_SHOPPING: 'Continue shopping',
	CHECK_OUT: 'Check out',
	SUBTOTAL: 'Subtotal',
	TOTAL: 'Total',
	SHIPPING: 'Shipping',
	SELECT_ADDRESS: 'Please, select one address',
	UNITS: 'Units',
	ORDER_SUMMARY: 'Order summary',
	ALL_FIELDS_REQUIRED: 'All fields are required',
	MESSAGE_SENT_SUCCESSFULLY: 'Message sent successfully',
	AN_ERROR_OCCURRED: 'An error occurred',
	SEND_MESSAGE: 'Send message',
	MESSAGE: 'Message',
	CALL_US: 'Call us',
	EMAIL_US: 'Email us',
	CONTACT_US_DESCRIPTION: `If you need help placing an order
					order or have any questions about our
					our shop, our team is available to answer your
					Monday to Friday, 9 am to 3 pm.
					3pm.`,
	NEWS_LETTER: 'Newsletter',
	NEWS_LETTER_LABEL: 'Enter your email',
	NEWS_LETTER_SIGNUP: 'Sign Up For Updates',
	NEWS_LETTER_BUTTON: 'Sign Up',
	ORDERING_AND_SHIPPING: 'Ordering and shipping',
	THE_FE_STORY: 'The Fina Ejerique Story',
	CAREERS: 'Careers',
	CUSTOMER_CARE: 'Customer Care',
	ABOUT_FE: 'About Fina Ejerique',
	FOLLOW_FE: 'Follow Fina Ejerique',
	VIEW_DETAILS: 'View details',
	ITEMS: 'Items',
	ORDER_NUMBER: 'Order number',
	REQUEST_RETURN: 'Request return',
	ERROR_ADDRESS_NAME: 'Please select a name for the address',
	ERROR_ADDRESS_FIRST_NAME: 'Please add you name',
	ERROR_ADDRESS_LAST_NAME: 'Please add you surname',
	ERROR_ADDRESS_COUNTRY: 'Please select your country',
	ERROR_ADDRESS_CITY: 'Please select your city',
	ERROR_ADDRESS_PROVINCE: 'Please select your province',
	ERROR_ADDRESS_POSTAL_CODE: 'Please select your postal code',
	ERROR_ADDRESS_STREET_AND_NUMBER: 'Please select your address',
	ERROR_ADDRESS_PHONE_NUMBER: 'Please select a valid phone number',
	NO_ADDRESSES: 'No addresses',
	BACK: 'Back',
	PERSONAL_DATA: 'Personal data',
	BIRTH_DATE: 'Birth date',
	SELECT_SIZE: 'Please, select a size',
	RETURN_ADDRESS: 'Return address',
	DRAFT: 'Draft',
	PENDING_PAYMENT: 'Pending payment',
	PAID: 'Paid',
	PAYMENT_FAILED: 'Payment failed',
	PENDING_REVIEW: 'Pending review',
	REVIEWED: 'Reviewed',
	SENT_TO_SIMSS: 'Sent to SIMSS',
	ERROR_SENDING_TO_SIMSS: 'Error sending to SIMSS',
	CARRIER_NOTIFIED: 'Carrier notified',
	ERROR_NOTIFYING_CARRIER: 'Error notifying carrier',
	SHIPPED: 'Shipped',
	DELIVERED: 'Delivered',
	COMPLETED: 'Completed',
	CANCELLED: 'Cancelled',
	PASSWORD_CHANGE: 'Change password',
	PASSWORD_CURRENT: 'Current Password',
	PASSWORD_NEW: 'New Password',
	PASSWORD_CONFIRM: 'Confirm New Password',
	PASSWORD_DETAILS:
		'Password must contain: <br />- At least 8 characters long <br />- At least one uppercase letter <br />- At least one lowercase letter <br /> - At least one number <br />- At least one special character (@, #, $, %, etc.)',
	PASSWORD_ERROR_OLD: 'Please add your old password',
	PASSWORD_ERROR_NEW: 'Please add your new password',
	PASSWORD_ERROR_CONFIRM: 'Please confirm your new password',
	PASSWORD_ERROR_NOT_MATCH: 'Passwords do not match',
	PASSWORD_ERROR_INCOMPLETE:
		'Password must contain at least 8 characters, one number, one letter and one special character.',
	PASSWORD_ERROR_INCORRECT: 'Current password is not correct. Please check it.',
	PASSWORD_ERROR_ERROR: 'An error occurred. Please try again later.',
	CART_BAG: 'Bag',
	CART_EMAIL: 'Email',
	CART_DELIVERY: 'Delivery',
	CART_PAYMENT: 'Payment',
	CHECK_EMAIL_COMMUNICATIONS:
		'I would like to receive exclusive email communications from Fina Ejerique.',
	CHECK_TERMS_AND_CONDITIONS:
		'By placing your order you agree to Fina Ejerique’s terms of website use and privacy policy.',
	PAYMENT_CONFIRM_AND_PAY: 'Confirm and pay',
	PAYMENT_ERROR_TERMS_CONDITIONS: 'You must accept the terms and conditions',
	PAYMENT_ERROR_PAYMENT_METHOD: 'You must select a payment method',
	PAYMENT_ERROR_CREATING_ORDER_ID: 'Error creating order',
	PAYMENT_ERROR_PAYMENT_METHOD_NOT_FOUND: 'Payment method not found',
	PAYMENT_ERROR_CREATING_ORDER_RETRY:
		'Error creating order. Please, try again later.',
	PAYMENT_CREDIT_CARD: 'Debit/Credit Card',
	PAYMENT_CREDIT_CARD_DESCRIPTION: 'User your card to pay',
	PAYMENT_BIZUM: 'Bizum',
	PAYMENT_BIZUM_DESCRIPTION: 'User Bizum to pay',
	SIGNUP_CREATE_ACCOUNT: 'Create Account',
	SIGNUP_EMAIL: 'Email Address',
	SIGNUP_FIRST_NAME: 'First Name',
	SIGNUP_LAST_NAME: 'Last Name',
	SIGNUP_BIRTHDAY: 'Date of birth (optional)',
	SIGNUP_COUNTRY: 'Choose country',
	SIGNUP_PASSWORD: 'Password',
	SIGNUP_CONFIRM_PASSWORD: 'Confirm Password',
	SIGNUP_ALREADY_EXISTS: 'Already have an account?',
	SIGNUP_SIGNIN: 'Sign In',
	SIGNUP_ERROR_INVALID_EMAIL: 'Invalid email address',
	SIGNUP_ERROR_INVALID_PASSWORD: 'Please provide a valid password',
	SIGNUP_ERROR_TERMS_CONDITIONS: 'You must accept the terms and conditions',
	SIGNUP_ERROR_PASSWORD_NOT_MATCH: 'Passwords do not match',
	SIGNUP_ERROR_COUNTRY: 'Please select a country',
	SIGNUP_ERROR_FIRST_NAME: 'Please provide your first name',
	SIGNUP_ERROR_LAST_NAME: 'Please provide your last name',
	SIGNUP_ERROR_RETRY: 'An error occurred. Please try again later',
	SIGNUP_ERROR_CHECK_FIELDS: 'There are errors in the form. Please check them',
	SIGNIN_CREATE_ACCOUNT: 'Sign in',
	SIGNIN_EMAIL: 'Email Address',
	SIGNIN_PASSWORD: 'Password',
	SIGNIN_PASSWORD_FORGOT: 'Forgotten your password?',
	SIGNIN_ACCOUNT_NOT_EXISTS: 'Don’t have an account?',
	SIGNIN_SIGNUP: 'Sign up',
	SIGNIN_ERROR_INVALID_EMAIL: 'Please enter a valid email address',
	SIGNUP_ERROR_DUPLICATED_EMAIL: 'Email already exists',
	SIGNIN_ERROR_SIGNIN: 'Login failed. Please check your credentials',
	SIGNIN_ERROR_RETRY: 'An error occurred. Please try again later',
	PRODUCT_CONTACT_US_DESCRIPTION:
		'If you need help placing an order or have any questions about our store, our team is available to answer your questions Monday to Friday, 9am to 3pm.',
	PRODUCT_CONTACT_US_BUTTON: 'Contact Us',
	FOOTER_PAGE_PAYMENT_TYPE_TITLE: 'Payment Methods',
	FOOTER_PAGE_DEVOLUTIONS: 'Returns are FREE.',
	FOOTER_PAGE_PAYMENT_TYPE_DESCRIPTION:
		'You can use the following payment methods: Visa, Visa Electron, Mastercard, Maestro, JCB and UPAY.',
	FOOTER_PAGE_PAYMENT_TYPE_DESCRIPTION_L1: '• The card has not expired.',
	FOOTER_PAGE_PAYMENT_TYPE_DESCRIPTION_L2:
		'• That your card does not exceed its validity date.',
	FOOTER_PAGE_PAYMENT_TYPE_DESCRIPTION_L3:
		'• The card limit may have been reached. Check with your bank that the card has not exceeded the amount allowed to make purchases.',
	FOOTER_PAGE_PAYMENT_TYPE_DESCRIPTION_L4:
		'• Check that you have correctly filled out all the necessary fields.',
	FOOTER_PAGE_INVOICE_QUESTION:
		'Can I obtain an invoice in the name of my company?',
	FOOTER_PAGE_INVOICE_ANSWER:
		'Yes. You just have to check the "company" option in personal data and cover the tax data that we ask for.',
	FOOTER_PAGE_SECURITY_TITLE: 'Security',
	FOOTER_PAGE_SECURITY_DESCRIPTION:
		'For Fina Ejerique, security in online purchases is essential. All transactions are carried out through secure payment systems and confidential payment data is transmitted directly and in encrypted form (SSL) to the entity. We have Sectigo RSA Domain Validation Secure Server CA SSL certificates and online transaction security certificates: Verified by VISA, MasterCard SecureCode and American Express Safekey',
	FOOTER_PAGE_DELIVERY: 'Shipping',
	DELIVERY_TITLE: 'FREE HOME DELIVERY OVER €50',
	DELIVERY_DESCRIPTION_1:
		'Delivery times range from 1 to 3 working days on the peninsula (1 or 2 more days in the Balearic Islands). No shipments are made to the Canary Islands.',
	DELIVERY_DESCRIPTION_2:
		'Delivery times are for orders placed from Monday to Friday before 09:00 am. After that time, the period will begin on the next business day. In special periods such as Black Friday or Sales, the shipping time may be longer than usual.',
	RETURNORDER_CHECK_TERMS_AND_CONDITIONS:
		'By returning your order, you accept the terms of use of the website and the privacy policy of Fina Ejerique.',
	RETURNORDER_DEVOLUTION_N: 'Devolution number',
	RETURNORDER_ORDER_N: 'Order number',
	RETURNORDER_BUY_DATE: 'Date of purchase',
	RETURNORDER_PRODUCTS: 'Products',
	RETURNORDER_SUBTOTAL: 'Subtotal',
	RETURNORDER_TOTAL: 'Total',
	RETURNORDER_SHIPPING: 'Shipping',
	NEWS_LETTER_SUCCESS: 'You have been subscribed',
	NEWS_LETTER_ERROR: 'An error occurred. Please try again later',
	NEWS_LETTER_ALREADY_SUSCRIBED: 'You are already subscribed',
	RETURNORDER_CONTACT_SUPPORT: 'Error. Please contact the technical support',
	RETURNORDER_NO_SELECTED_PRODUCTS: 'There are no products selected',
	RETURNORDER_SELECT_TERMS_CONDITIONS:
		'You must accept the Terms and Conditions to continue.',
	RETURNORDER_FIRST_TIME_PREVIEW_MESSAGE:
		'Your return request has been successfully created. You will receive an email with the return instructions.',
	PASSWORD_RECOVERY: 'Password Recovery',
	PASSWORD_RECOVERY_DESCRIPTION:
		'Enter your email address and we will send you an email with a new password',
	PASSWORD_RECOVERY_EMAIL: 'Email',
	PASSWORD_RECOVERY_ERROR_INVALID_EMAIL: 'Please enter a valid email address',
	PASSWORD_RECOVERY_ERROR_RETRY: 'An error occurred. Please try again later',
	PASSWORD_RECOVERY_ERROR_EMAIL_NOT_FOUND: 'Email not found',
	PASSWORD_RECOVERY_ERROR_EMAIL_NOT_SENT:
		'An error occurred. Email not sent. Please try again later',
	PASSWORD_RECOVERY_SUCCESS:
		'An email has been sent to your account with a new password',
	PASSWORD_RECOVERY_ERROR: 'An error occurred. Please try again later',
	PASSWORD_RECOVERY_ERROR_EMAIL: 'Please enter a valid email address',
	PASSWORD_RECOVERY_SUBMIT: 'Submit',
	Draft: 'Draft',
	'Pending payment': 'Pending payment',
	Paid: 'Paid',
	'Payment failed': 'Payment failed',
	'Paid pending review': 'Paid pending review',
	Reviewed: 'Reviewed',
	'Sent to SIMSS': 'Sent to SIMSS',
	'Error sending to SIMSS': 'Error sending to SIMSS',
	'Carrier notified': 'Carrier notified',
	'Error notifying carrier': 'Error notifying carrier',
	Shipped: 'Shipped',
	Delivered: 'Delivered',
	Completed: 'Completed',
	Cancelled: 'Cancelled',
	PAGE_NOT_FOUND: 'Page not found',
	NO_PRODUCTS_FOUND: 'No products found for this search criteria',
}

export const en: ResourceLanguage = {
	translation: translations,
}

export default en
