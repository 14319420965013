import { Box, Typography } from '@mui/material'
import { IRoute } from '../../interfaces/route.interface'
import PartnersLogos from '../../assets/images/partners/partners.png'
import { LazyImage } from '../lazy-image/lazy-image'
import { LazyImageUnknownHeight } from '../lazy-image/lazy-image-unknown-height'

export interface PartnersPageProps extends IRoute {}

const PartnersPage = (props: PartnersPageProps) => {
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				padding: '2% 15% 5%',
				textAlign: 'justify',
				gap: '1.5rem',
			}}
		>
			<Typography
				variant='h4'
				sx={{
					marginBottom: '1rem',
				}}
			>
				Partners
			</Typography>
			<Typography>
				GLOBOTEX SL, ha sido beneficiaria de Fondos Europeos, cuyo objetivo es
				el refuerzo del crecimiento sostenible y la competitividad de las PYMES,
				y gracias al cual ha puesto en marcha un Plan de Acción con el objetivo
				de mejorar su competitividad mediante la transformación digital, la
				promoción online y el comercio electrónico en mercados internacionales
				durante el año 2024. Para ello ha contado con el apoyo del Programa
				Xpande Digital de la Cámara de Comercio de Valencia. #EuropaSeSiente
			</Typography>
			<Typography>
				GLOBOTEX SOCIEDAD DE RESPONSABILIDAD LIMITADA, ha sido beneficiaria de
				Fondos Europeos, cuyo objetivo es el refuerzo del crecimiento sostenible
				y la competitividad de las PYMES, y gracias al cual ha puesto en marcha
				un Plan de Internacionalización con el objetivo de mejorar su
				posicionamiento competitivo en el exterior durante el año 2024. Para
				ello ha contado con el apoyo del Programa Xpande Digital de la Cámara de
				Comercio de Valencia. #EuropaSeSiente
			</Typography>
			<img
				src={PartnersLogos}
				alt='partners logos'
				style={{
					width: '100%',
					maxWidth: '800px',
					height: 'auto',
				}}
			/>
		</Box>
	)
}

export default PartnersPage
