export enum MenuCategoryTranslations {
	BABY = 'BABY',
	NEW_BORN = 'NEW_BORN',
	CHILDREN = 'CHILDREN',
}

export enum AccountRoutes {
	ACCOUNT_OVERVIEW = 'ACCOUNT_OVERVIEW',
	ORDER_HISTORY = 'ORDER_HISTORY',
	ORDER_VIEW = 'ORDER_VIEW',
	ADDRESS_BOOK = 'ADDRESS_BOOK',
	ADDRESS_EDIT = 'ADDRESS_EDIT',
	ADDRESS_NEW = 'ADDRESS_NEW',
	CHANGE_PASSWORD = 'CHANGE_PASSWORD',
	MY_INFO = 'MY_INFO',
	SIGN_OUT = 'SIGN_OUT',
	ORDER_RETURN = 'ORDER_RETURN',
	RETURNS = 'RETURNS',
	RETURN_VIEW = 'RETURN_VIEW',
	WISHLIST = 'WISHLIST',
}

export enum Translations {
	SEARCH = 'SEARCH',
	COOKIE_POLICY = 'COOKIE_POLICY',
	TERMS_CONDITIONS = 'TERMS_CONDITIONS',
	PRIVACY_POLICY = 'PRIVACY_POLICY',
	FAQS = 'FAQS',
	SPANISH = 'SPANISH',
	ENGLISH = 'ENGLISH',
	ITALIAN = 'ITALIAN',
	PORTUGUESE = 'PORTUGUESE',
	SHOPPING_BAG = 'SHOPPING_BAG',
	WISHLIST = 'WISHLIST',
	MYFINAEJERIQUE = 'MYFINAEJERIQUE',
	CUSTOMER_SERVICE = 'CUSTOMER_SERVICE',
	NEED_HELP_ORDER = 'NEED_HELP_ORDER',
	COLOR = 'COLOR',
	SIZE = 'SIZE',
	SIZE_CHART = 'SIZE_CHART',
	REGION = 'REGION',
	LANGUAGE = 'LANGUAGE',
	SHIPPING_TO = 'SHIPPING_TO',
	SHIPPING_LOCATION = 'SHIPPING_LOCATION',
	SIGN_IN = 'SIGN_IN',
	SIGN_OUT = 'SIGN_OUT',
	SIGN_UP = 'SIGN_UP',
	LOADING = 'LOADING',
	DESCRIPTION = 'DESCRIPTION',
	DETAILS = 'DETAILS',
	DELIVERY = 'DELIVERY',
	CONTACT_US = 'CONTACT_US',
	SIMILAR_PRODUCTS = 'SIMILAR_PRODUCTS',
	YOU_MAY_ALSO_LIKE = 'YOU_MAY_ALSO_LIKE',
	ADD_TO_CART = 'ADD_TO_CART',
	CLEAR_FILTERS = 'CLEAR_FILTERS',
	FILTER = 'FILTER',
	APPLY = 'APPLY',
	CONTINUE_TO_PAYMENT = 'CONTINUE_TO_PAYMENT',
	ORDER_HISTORY_DESCRIPTION = 'ORDER_HISTORY_DESCRIPTION',
	ADDRESS_BOOK_DESCRIPTION = 'ADDRESS_BOOK_DESCRIPTION',
	PASSWORD_CHANGE_DESCRIPTION = 'PASSWORD_CHANGE_DESCRIPTION',
	RETURN_DESCRIPTION = 'RETURN_DESCRIPTION',
	WISHLIST_DESCRIPTION = 'WISHLIST_DESCRIPTION',
	ACCOUNT_OVERVIEW_DESCRIPTION = 'ACCOUNT_OVERVIEW_DESCRIPTION',
	EDIT = 'EDIT',
	DELETE = 'DELETE',
	CONTINUE_SHOPPING = 'CONTINUE_SHOPPING',
	CHECK_OUT = 'CHECK_OUT',
	SUBTOTAL = 'SUBTOTAL',
	TOTAL = 'TOTAL',
	SHIPPING = 'SHIPPING',
	UNITS = 'UNITS',
	ORDER_SUMMARY = 'ORDER_SUMMARY',
	ALL_FIELDS_REQUIRED = 'ALL_FIELDS_REQUIRED',
	MESSAGE_SENT_SUCCESSFULLY = 'MESSAGE_SENT_SUCCESSFULLY',
	AN_ERROR_OCCURRED = 'AN_ERROR_OCCURRED',
	SEND_MESSAGE = 'SEND_MESSAGE',
	MESSAGE = 'MESSAGE',
	CALL_US = 'CALL_US',
	EMAIL_US = 'EMAIL_US',
	CONTACT_US_DESCRIPTION = 'CONTACT_US_DESCRIPTION',
	NEWS_LETTER = 'NEWS_LETTER',
	NEWS_LETTER_LABEL = 'NEWS_LETTER_LABEL', // Enter your email
	NEWS_LETTER_SIGNUP = 'NEWS_LETTER_SIGNUP', // Sign Up For Updates
	NEWS_LETTER_BUTTON = 'NEWS_LETTER_BUTTON',
	NEWS_LETTER_SUCCESS = 'NEWS_LETTER_SUCCESS',
	NEWS_LETTER_ALREADY_SUSCRIBED = 'NEWS_LETTER_ALREADY_SUSCRIBED',
	NEWS_LETTER_ERROR = 'NEWS_LETTER_ERROR',
	ORDERING_AND_SHIPPING = 'ORDERING_AND_SHIPPING',
	THE_FE_STORY = 'THE_FE_STORY',
	CAREERS = 'CAREERS',
	CUSTOMER_CARE = 'CUSTOMER_CARE',
	ABOUT_FE = 'ABOUT_FE',
	FOLLOW_FE = 'FOLLOW_FE',
	VIEW_DETAILS = 'VIEW_DETAILS',
	ITEMS = 'ITEMS',
	ORDER_NUMBER = 'ORDER_NUMBER',
	REQUEST_RETURN = 'REQUEST_RETURN',
	BACK = 'BACK',
	PERSONAL_DATA = 'PERSONAL_DATA',
	BIRTH_DATE = 'BIRTH_DATE',
	SELECT_SIZE = 'SELECT_SIZE',
	CHECK_EMAIL_COMMUNICATIONS = 'CHECK_EMAIL_COMMUNICATIONS',
	CHECK_TERMS_AND_CONDITIONS = 'CHECK_TERMS_AND_CONDITIONS',
}

export enum AddressTranslations {
	ADDRESS = 'ADDRESS',
	ADDRESS_NO_ADDRESSES = 'ADDRESS_NO_ADDRESSES',
	ADDRESSES = 'ADDRESSES',
	ADD_ADDRESS = 'ADD_ADDRESS',
	EDIT_ADDRESS = 'EDIT_ADDRESS',
	ADDRESS_FIRST_NAME = 'ADDRESS_FIRST_NAME',
	ADDRESS_LAST_NAME = 'ADDRESS_LAST_NAME',
	ADDRESS_COUNTRY = 'ADDRESS_COUNTRY',
	ADDRESS_CITY = 'ADDRESS_CITY',
	ADDRESS_PROVINCE = 'ADDRESS_PROVINCE',
	ADDRESS_POSTAL_CODE = 'ADDRESS_POSTAL_CODE',
	ADDRESS_STREET_AND_NUMBER = 'ADDRESS_STREET_AND_NUMBER',
	ADDRESS_ADDITIONAL_DATA = 'ADDRESS_ADDITIONAL_DATA',
	ADDRESS_PHONE_NUMBER = 'ADDRESS_PHONE_NUMBER',
	DELIVERY_ADDRESS = 'DELIVERY_ADDRESS',
	ADDRESS_NAME = 'ADDRESS_NAME',
	SAVE_ADDRESS = 'SAVE_ADDRESS',
	SELECT_ADDRESS = 'SELECT_ADDRESS',
	NO_ADDRESSES = 'NO_ADDRESSES',
	RETURN_ADDRESS = 'RETURN_ADDRESS',
}

export enum DeliveryStatusTranslations {
	DRAFT = 'DRAFT',
	PENDING_PAYMENT = 'PENDING_PAYMENT',
	PAID = 'PAID',
	PAYMENT_FAILED = 'PAYMENT_FAILED',
	PENDING_REVIEW = 'PENDING_REVIEW',
	REVIEWED = 'REVIEWED',
	SENT_TO_SIMSS = 'SENT_TO_SIMSS',
	ERROR_SENDING_TO_SIMSS = 'ERROR_SENDING_TO_SIMSS',
	CARRIER_NOTIFIED = 'CARRIER_NOTIFIED',
	ERROR_NOTIFYING_CARRIER = 'ERROR_NOTIFYING_CARRIER',
	SHIPPED = 'SHIPPED',
	DELIVERED = 'DELIVERED',
	COMPLETED = 'COMPLETED',
	CANCELLED = 'CANCELLED',
}

export enum AddressErrors {
	ERROR_ADDRESS_NAME = 'ERROR_ADDRESS_NAME',
	ERROR_ADDRESS_FIRST_NAME = 'ERROR_ADDRESS_FIRST_NAME',
	ERROR_ADDRESS_LAST_NAME = 'ERROR_ADDRESS_LAST_NAME',
	ERROR_ADDRESS_COUNTRY = 'ERROR_ADDRESS_COUNTRY',
	ERROR_ADDRESS_CITY = 'ERROR_ADDRESS_CITY',
	ERROR_ADDRESS_PROVINCE = 'ERROR_ADDRESS_PROVINCE',
	ERROR_ADDRESS_POSTAL_CODE = 'ERROR_ADDRESS_POSTAL_CODE',
	ERROR_ADDRESS_STREET_AND_NUMBER = 'ERROR_ADDRESS_STREET_AND_NUMBER',
	ERROR_ADDRESS_PHONE_NUMBER = 'ERROR_ADDRESS_PHONE_NUMBER',
}

export enum FilterColor {
	RED_PALETTE = 'RED_PALETTE',
	PINK_PALETTE = 'PINK_PALETTE',
	PURPLE_PALETTE = 'PURPLE_PALETTE',
	BLUE_PALETTE = 'BLUE_PALETTE',
	GREEN_PALETTE = 'GREEN_PALETTE',
	YELLOW_PALETTE = 'YELLOW_PALETTE',
	ORANGE_PALETTE = 'ORANGE_PALETTE',
	BROWN_PALETTE = 'BROWN_PALETTE',
	GRAY_PALETTE = 'GRAY_PALETTE',
	WHITE_PALETTE = 'WHITE_PALETTE',
	BLACK_PALETTE = 'BLACK_PALETTE',
}

export enum Password {
	PASSWORD_CHANGE = 'PASSWORD_CHANGE',
	PASSWORD_CURRENT = 'PASSWORD_CURRENT',
	PASSWORD_NEW = 'PASSWORD_NEW',
	PASSWORD_CONFIRM = 'PASSWORD_CONFIRM',
	PASSWORD_DETAILS = 'PASSWORD_DETAILS',
	PASSWORD_ERROR_OLD = 'PASSWORD_ERROR_OLD',
	PASSWORD_ERROR_NEW = 'PASSWORD_ERROR_NEW',
	PASSWORD_ERROR_CONFIRM = 'PASSWORD_ERROR_CONFIRM',
	PASSWORD_ERROR_NOT_MATCH = 'PASSWORD_ERROR_NOT_MATCH',
	PASSWORD_ERROR_INCOMPLETE = 'PASSWORD_ERROR_INCOMPLETE',
	PASSWORD_ERROR_INCORRECT = 'PASSWORD_ERROR_INCORRECT',
	PASSWORD_ERROR_ERROR = 'PASSWORD_ERROR_ERROR',
}

export enum Cart {
	CART_BAG = 'CART_BAG',
	CART_EMAIL = 'CART_EMAIL',
	CART_DELIVERY = 'CART_DELIVERY',
	CART_PAYMENT = 'CART_PAYMENT',
}

export enum Payment {
	PAYMENT_CONFIRM_AND_PAY = 'PAYMENT_CONFIRM_AND_PAY',
	PAYMENT_CREDIT_CARD = 'PAYMENT_CREDIT_CARD',
	PAYMENT_CREDIT_CARD_DESCRIPTION = 'PAYMENT_CREDIT_CARD_DESCRIPTION',
	PAYMENT_BIZUM = 'PAYMENT_BIZUM',
	PAYMENT_BIZUM_DESCRIPTION = 'PAYMENT_BIZUM_DESCRIPTION',
	PAYMENT_ERROR_TERMS_CONDITIONS = 'PAYMENT_ERROR_TERMS_CONDITIONS',
	PAYMENT_ERROR_PAYMENT_METHOD = 'PAYMENT_ERROR_PAYMENT_METHOD',
	PAYMENT_ERROR_CREATING_ORDER_ID = 'PAYMENT_ERROR_CREATING_ORDER_ID',
	PAYMENT_ERROR_PAYMENT_METHOD_NOT_FOUND = 'PAYMENT_ERROR_PAYMENT_METHOD_NOT_FOUND',
	PAYMENT_ERROR_CREATING_ORDER_RETRY = 'PAYMENT_ERROR_CREATING_ORDER_RETRY',
}

export enum SignUp {
	SIGNUP_CREATE_ACCOUNT = 'SIGNUP_CREATE_ACCOUNT',
	SIGNUP_EMAIL = 'SIGNUP_EMAIL',
	SIGNUP_FIRST_NAME = 'SIGNUP_FIRST_NAME',
	SIGNUP_LAST_NAME = 'SIGNUP_LAST_NAME',
	SIGNUP_BIRTHDAY = 'SIGNUP_BIRTHDAY',
	SIGNUP_COUNTRY = 'SIGNUP_COUNTRY',
	SIGNUP_PASSWORD = 'SIGNUP_PASSWORD',
	SIGNUP_CONFIRM_PASSWORD = 'SIGNUP_CONFIRM_PASSWORD',
	SIGNUP_ALREADY_EXISTS = 'SIGNUP_ALREADY_EXISTS',
	SIGNUP_SIGNIN = 'SIGNUP_SIGNIN',
	SIGNUP_ERROR_INVALID_EMAIL = 'SIGNUP_ERROR_INVALID_EMAIL',
	SIGNUP_ERROR_INVALID_PASSWORD = 'SIGNUP_ERROR_INVALID_PASSWORD',
	SIGNUP_ERROR_TERMS_CONDITIONS = 'SIGNUP_ERROR_TERMS_CONDITIONS',
	SIGNUP_ERROR_PASSWORD_NOT_MATCH = 'SIGNUP_ERROR_PASSWORD_NOT_MATCH',
	SIGNUP_ERROR_COUNTRY = 'SIGNUP_ERROR_COUNTRY',
	SIGNUP_ERROR_FIRST_NAME = 'SIGNUP_ERROR_FIRST_NAME',
	SIGNUP_ERROR_LAST_NAME = 'SIGNUP_ERROR_LAST_NAME',
	SIGNUP_ERROR_RETRY = 'SIGNUP_ERROR_RETRY',
	SIGNUP_ERROR_DUPLICATED_EMAIL = 'SIGNUP_ERROR_DUPLICATED_EMAIL',
	SIGNUP_ERROR_CHECK_FIELDS = 'SIGNUP_ERROR_CHECK_FIELDS',
}

export enum SignIn {
	SIGNIN_CREATE_ACCOUNT = 'SIGNIN_CREATE_ACCOUNT',
	SIGNIN_EMAIL = 'SIGNIN_EMAIL',
	SIGNIN_PASSWORD = 'SIGNIN_PASSWORD',
	SIGNIN_PASSWORD_FORGOT = 'SIGNIN_PASSWORD_FORGOT',
	SIGNIN_ACCOUNT_NOT_EXISTS = 'SIGNIN_ACCOUNT_NOT_EXISTS',
	SIGNIN_SIGNUP = 'SIGNIN_SIGNUP',
	SIGNIN_ERROR_INVALID_EMAIL = 'SIGNIN_ERROR_INVALID_EMAIL',
	SIGNIN_ERROR_SIGNIN = 'SIGNIN_ERROR_SIGNIN',
	SIGNIN_ERROR_RETRY = 'SIGNIN_ERROR_RETRY',
}

export enum PasswordRecover {
	PASSWORD_RECOVERY = 'PASSWORD_RECOVERY',
	PASSWORD_RECOVERY_DESCRIPTION = 'PASSWORD_RECOVERY_DESCRIPTION',
	PASSWORD_RECOVERY_EMAIL = 'PASSWORD_RECOVERY_EMAIL',
	PASSWORD_RECOVERY_ERROR_INVALID_EMAIL = 'PASSWORD_RECOVERY_ERROR_INVALID_EMAIL',
	PASSWORD_RECOVERY_ERROR_RETRY = 'PASSWORD_RECOVERY_ERROR_RETRY',
	PASSWORD_RECOVERY_SUBMIT = 'PASSWORD_RECOVERY_SUBMIT',
	PASSWORD_RECOVERY_SUCCESS = 'PASSWORD_RECOVERY_SUCCESS',
	PASSWORD_RECOVERY_ERROR_EMAIL_NOT_FOUND = 'PASSWORD_RECOVERY_ERROR_EMAIL_NOT_FOUND',
	PASSWORD_RECOVERY_ERROR_EMAIL_NOT_SENT = 'PASSWORD_RECOVERY_ERROR_EMAIL_NOT_SENT',
	PASSWORD_RECOVERY_ERROR = 'PASSWORD_RECOVERY_ERROR',
	PASSWORD_RECOVERY_ERROR_EMAIL = 'PASSWORD_RECOVERY_ERROR_EMAIL',
}

export enum Product {
	PRODUCT_CONTACT_US_DESCRIPTION = 'PRODUCT_CONTACT_US_DESCRIPTION',
	PRODUCT_CONTACT_US_BUTTON = 'PRODUCT_CONTACT_US_BUTTON',
}

export enum FooterPage {
	FOOTER_PAGE_PAYMENT_TYPE_TITLE = 'FOOTER_PAGE_PAYMENT_TYPE_TITLE',
	FOOTER_PAGE_DEVOLUTIONS = 'FOOTER_PAGE_DEVOLUTIONS',
	FOOTER_PAGE_PAYMENT_TYPE_DESCRIPTION = 'FOOTER_PAGE_PAYMENT_TYPE_DESCRIPTION',
	FOOTER_PAGE_PAYMENT_TYPE_DESCRIPTION_L1 = 'FOOTER_PAGE_PAYMENT_TYPE_DESCRIPTION_L1',
	FOOTER_PAGE_PAYMENT_TYPE_DESCRIPTION_L2 = 'FOOTER_PAGE_PAYMENT_TYPE_DESCRIPTION_L2',
	FOOTER_PAGE_PAYMENT_TYPE_DESCRIPTION_L3 = 'FOOTER_PAGE_PAYMENT_TYPE_DESCRIPTION_L3',
	FOOTER_PAGE_PAYMENT_TYPE_DESCRIPTION_L4 = 'FOOTER_PAGE_PAYMENT_TYPE_DESCRIPTION_L4',
	FOOTER_PAGE_INVOICE_QUESTION = 'FOOTER_PAGE_INVOICE_QUESTION',
	FOOTER_PAGE_INVOICE_ANSWER = 'FOOTER_PAGE_INVOICE_ANSWER',
	FOOTER_PAGE_SECURITY_TITLE = 'FOOTER_PAGE_SECURITY_TITLE',
	FOOTER_PAGE_SECURITY_DESCRIPTION = 'FOOTER_PAGE_SECURITY_DESCRIPTION',
	FOOTER_PAGE_DELIVERY = 'FOOTER_PAGE_DELIVERY',
}

export enum Delivery {
	DELIVERY_TITLE = 'DELIVERY_TITLE',
	DELIVERY_DESCRIPTION_1 = 'DELIVERY_DESCRIPTION_1',
	DELIVERY_DESCRIPTION_2 = 'DELIVERY_DESCRIPTION_2',
}

export enum ReturnOrder {
	RETURNORDER_CHECK_TERMS_AND_CONDITIONS = 'RETURNORDER_CHECK_TERMS_AND_CONDITIONS',
	RETURNORDER_DEVOLUTION_N = 'RETURNORDER_DEVOLUTION_N',
	RETURNORDER_ORDER_N = 'RETURNORDER_ORDER_N',
	RETURNORDER_BUY_DATE = 'RETURNORDER_BUY_DATE',
	RETURNORDER_PRODUCTS = 'RETURNORDER_PRODUCTS',
	RETURNORDER_SUBTOTAL = 'RETURNORDER_SUBTOTAL',
	RETURNORDER_TOTAL = 'RETURNORDER_TOTAL',
	RETURNORDER_SHIPPING = 'RETURNORDER_SHIPPING',
	RETURNORDER_CONTACT_SUPPORT = 'RETURNORDER_CONTACT_SUPPORT',
	RETURNORDER_NO_SELECTED_PRODUCTS = 'RETURNORDER_NO_SELECTED_PRODUCTS',
	RETURNORDER_SELECT_TERMS_CONDITIONS = 'RETURNORDER_SELECT_TERMS_CONDITIONS',
	RETURNORDER_FIRST_TIME_PREVIEW_MESSAGE = 'RETURNORDER_FIRST_TIME_PREVIEW_MESSAGE',
}

export enum OrderStatusTranslation {
	// El pedido ha sido creado pero aún no se ha completado.
	DRAFT = 'Draft',

	// El pedido esta pendiente de pago.
	PENDING_PAYMENT = 'Pending payment',

	// El pedido ha sido pagado por el usuario
	PAID = 'Paid',

	// El proceso de pago ha finalizado como KO
	PAYMENT_FAILED = 'Payment failed',

	// Pedido pagado y pendiente de revision
	PENDING_REVIEW = 'Paid pending review',

	// El pedido ha sido revisado y puede continuar el flujo
	REVIEWED = 'Reviewed',

	// El pedido ha sido enviado a SIMSS
	SENT_TO_SIMSS = 'Sent to SIMSS',

	// Error al enviar el pedido a SIMSS
	ERROR_SENDING_TO_SIMSS = 'Error sending to SIMSS',

	// Avisado el transportista
	CARRIER_NOTIFIED = 'Carrier notified',

	// Error al avisar al transportista
	ERROR_NOTIFYING_CARRIER = 'Error notifying carrier',

	// El pedido ha sido enviado al cliente y está en tránsito.
	SHIPPED = 'Shipped',

	// El pedido ha sido entregado al cliente en el destino especificado.
	DELIVERED = 'Delivered',

	// El pedido ha sido entregado y todas las transacciones y procesos relacionados han concluido.
	COMPLETED = 'Completed',

	// El pedido ha sido cancelado y no se procederá con el envío ni otros procesos.
	CANCELLED = 'Cancelled',
}

export enum ErrorTranslations {
	PAGE_NOT_FOUND = 'PAGE_NOT_FOUND',
	NO_PRODUCTS_FOUND = 'NO_PRODUCTS_FOUND',
}
